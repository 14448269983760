import React from "react"
import { Link, graphql } from "gatsby"
import updatedAt from "../helpers/updated_at"
import Layout from "../layouts"

const IndexPage = ({ data: pageData }) => (
  <Layout>
    <header
      css={{
        gridColumn: 2,
        gridRow: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <Link to="hire-me">Blog</Link> */}
      <Link to="hire-me" className="button" css={{ marginLeft: "auto" }}>
        Hire Me
      </Link>
    </header>
    <main css={{ gridColumn: 2, gridRow: 2 }}>
      <p>Hey there!</p>
      <p className="aside">This is a four minute read, tops. I promise.</p>
      <p className="aside">Updated {updatedAt(pageData)}</p>
      <p>
        Thanks for visiting my website. I’m <strong>Will</strong>, as you might
        have guessed. I’m a software developer in{" "}
        <strong>Fort Worth, TX</strong>.
      </p>
      <p>
        I’ve been doing software development for <strong>9 years</strong>, and
        as a career for 6 years. I started when I was in high school, working on
        a start up with another high school student, which eventually got us a
        write up in the <strong>New York Times</strong>. Sadly, the business did
        not work out, it turns out that running a business is more difficult
        than software development. I continued to work on a couple of small
        development contracts while at college before leaving early to start
        working full-time developing web applications.
      </p>
      <p>
        Shortly after leaving college, I joined a remote agency called{" "}
        <strong>UpTrending</strong>. I stayed with them for about 3 years, doing
        Rails development. They started pivoting to doing more Wordpress sites,
        and I had been their only on staff Rails developer for the last 9
        months. I was approached by a local app development agency,{" "}
        <strong>Oven Bits</strong>, and joined with them in the Fall of 2015.
      </p>
      <p>
        I got to work on some great projects while I was there. I helped build
        the infrastructure for a <strong>wifi enabled grill/smoker</strong>. I
        built the backend for an app that let people virtually try different
        shades of lip stick and share photos of themselves with friends to let
        them vote on which shade you look best in 💄👄💁‍
      </p>
      <p>
        Unfortunately, Oven Bits fell on hard times and had to let go of just
        about everyone on staff. They’ve since rebounded and are back making
        wonderful applications. If you’re looking for a larger team that can be
        a one stop shop for design, mobile app development, and web application
        development, definitely check them out.
      </p>
      <p>
        I had to leave Oven Bits a little over a year after I started there. The
        unplanned unemployment gave me a chance to try being an{" "}
        <strong>independent contractor</strong>. It has always been something I
        was interested in doing, but was too scared to try when I already had
        the stability of full-time employment. It’s been a great experience, and
        I love getting to help <strong>solve problems</strong> for my clients.
      </p>
      <p>Here’s a quick list of some of the people I’ve worked with:</p>
      <p>
        <strong>Cru</strong>, a Christian outreach ministry. I helped their
        internal team on a project to <strong>rebuild an internal tool</strong>{" "}
        that they have for their missionaries to keep track of their supporters.
      </p>
      <p>
        <strong>Benchmark Wine Group</strong>, a Napa Valley based wine retailer
        that sells fine and rare wines to collectors and restaurants around the
        world. I actually started working with them at UpTrending, building a
        brand <strong>new in-house system</strong> to manage their inventory. It
        also powers the customer facing side of their site, allowing customers
        to <strong>browse and purchase wine</strong>. I continue to work with
        them, adding new features and performing maintenance for their
        application.
      </p>
      <p>
        <strong>Qeepsake</strong>, a text message baby journal. They text you
        questions about your kids and compile them for you into a book. I’ve
        been helping them add new features to their service and helping them
        build the <strong>backend infrastructure</strong> for their new mobile
        apps.
      </p>
      <p>
        <strong>Bethel Media</strong>, the media arm of Bethel Church in
        Redding, CA. I’ve been helping augment their team for the launch of a
        new version of Bethel.tv, their <strong>streaming service</strong> for
        conferences, sermons, worship sets, classes, and original programming.
      </p>
      <p>
        When I’m not working, I can be found playing my favorite music for my{" "}
        <strong>7 month old son</strong>, trying to inculcate him into liking my
        music. I’ve been told this may backfire, but I’m pressing on regardless.
        My wife and I also like hunting down new restaurants and pretending that
        we’re “foodies”, even though I maintain the diet of a 7 year old. I love
        movies and TV shows, and would be happy to talk to you about Breaking
        Bad, Better Call Saul, Arrested Development, or Seinfeld all day.
      </p>
      <p>
        I also enjoy playing racquet sports. I’m very competitive when it comes
        to <strong>racquetball</strong>, ping pong, or Mario Tennis. Also Mario
        Kart. I welcome any and all challengers.
      </p>
      <p>
        Thanks for taking the time to read this! If you have a project in mind
        that I can help out with, feel free to{" "}
        <a href="mailto:will@willcosgrove.com">reach out and say hi!</a> I would
        love to help.
      </p>
    </main>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query MyQuery {
    file(relativePath: { eq: "pages/index.js" }) {
      modifiedTime
    }
  }
`
